export type FieldDefinition = {
  field: string,
  fieldCamelCase: string,
  displayName?:string,
  type: "string" | "number" | "boolean" | "object" | "date",
}

export const UserFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "assessment_id",
    fieldCamelCase: "assessmentId",
    type: "number",
  },
  {
    field: "email",
    displayName: "メールアドレス",
    fieldCamelCase: "email",
    type: "string",
  },
  {
    field: "tel",
    displayName: "電話番号",
    fieldCamelCase: "tel",
    type: "string",
  },
  {
    field: "name",
    displayName: "ユーザー名",
    fieldCamelCase: "name",
    type: "string",
  },
  {
    field: "password_digest",
    fieldCamelCase: "passwordDigest",
    type: "string",
  },
  {
    field: "age",
    displayName: "年齢",
    fieldCamelCase: "age",
    type: "number",
  },
  {
    field: "status",
    displayName: "ステータス",
    fieldCamelCase: "status",
    type: "number",
  },
  {
    field: "birthday",
    displayName: "生年月日",
    fieldCamelCase: "birthday",
    type: "date",
  },
  {
    field: "introduction",
    displayName: "自己紹介",
    fieldCamelCase: "introduction",
    type: "string",
  },
  {
    field: "important_thing",
    displayName: "日々の生活で大切にしていること",
    fieldCamelCase: "importantThing",
    type: "string",
  },
  {
    field: "motivation",
    displayName: "テンションが上がる瞬間",
    fieldCamelCase: "motivation",
    type: "string",
  },
  {
    field: "hobby",
    displayName: "好きな場所、映画、音楽、マンガ、アニメ、絵、ゲーム、スポーツ…etc",
    fieldCamelCase: "hobby",
    type: "string",
  },
  {
    field: "failed_experience",
    displayName: "これまでに最も失敗したこと",
    fieldCamelCase: "failedExperience",
    type: "string",
  },
  {
    field: "sence_of_accomplishment",
    displayName: "これまでに最も達成感があったできごと",
    fieldCamelCase: "senceOfAccomplishment",
    type: "string",
  },
  {
    field: "spending_in_holiday",
    displayName: "休みの日の過ごし方",
    fieldCamelCase: "spendingInHoliday",
    type: "string",
  },
  {
    field: "dream",
    displayName: "将来の夢や、やりたいこと",
    fieldCamelCase: "dream",
    type: "string",
  },
  {
    field: "favorite_word",
    displayName: "好きな言葉とその理由",
    fieldCamelCase: "favoriteWord",
    type: "string",
  },
  {
    field: "seeking_for_work",
    displayName: "仕事に対して求めること",
    fieldCamelCase: "seekingForWork",
    type: "string",
  },
  {
    field: "publish_a",
    displayName: "診断A「生まれ持った普遍的な資質」の開示",
    fieldCamelCase: "publishA",
    type: "boolean",
  },
  {
    field: "publish_b",
    displayName: "診断B今の自分の才能と正確の開示",
    fieldCamelCase: "publishB",
    type: "boolean",
  },
  {
    field: "publish_c",
    displayName: "診断C対人関係で出やすい性格の開示",
    fieldCamelCase: "publishC",
    type: "boolean",
  },
  {
    field: "publish_d",
    displayName: "診断D理想とする人物像の開示",
    fieldCamelCase: "publishD",
    type: "boolean",
  },
  {
    field: "created_at",
    displayName: "登録日",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    displayName: "更新日",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
  {
    field: "job_hunting",
    displayName: "求職活動中",
    fieldCamelCase: "jobHunting",
    type: "boolean",
  },
  {
    field: "is_offered",
    displayName: "オファーを受ける",
    fieldCamelCase: "isOffered",
    type: "boolean",
  },
  {
    field: "email_verified",
    displayName: "メール認証",
    fieldCamelCase: "emailVerified",
    type: "boolean",
  },
  {
    field: "student",
    displayName: "在学中",
    fieldCamelCase: "student",
    type: "boolean",
  },
]

export const CompanyFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "email",
    displayName: "メールアドレス",
    fieldCamelCase: "email",
    type: "string",
  },
  {
    field: "password_digest",
    fieldCamelCase: "passwordDigest",
    type: "string",
  },
  {
    field: "department",
    displayName: "求人を行う部署",
    fieldCamelCase: "department",
    type: "string",
  },
  {
    field: "business",
    displayName: "企業の業界・業種",
    fieldCamelCase: "business",
    type: "string",
  },
  {
    field: "business_id",
    displayName: "企業の業界・業種ID",
    fieldCamelCase: "businessId",
    type: "number",
  },
  {
    field: "recruiter",
    displayName: "採用担当者",
    fieldCamelCase: "recruiter",
    type: "string",
  },
  {
    field: "status",
    displayName: "ステータス",
    fieldCamelCase: "status",
    type: "number",
  },
  {
    field: "recruiting",
    fieldCamelCase: "recruiting",
    type: "boolean",
  },
  {
    field: "str_recruiting",
    displayName: "採用中",
    fieldCamelCase: "strRecruiting",
    type: "string",
  },
  {
    field: "created_at",
    displayName: "登録日",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    displayName: "更新日",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
  {
    field: "register_type",
    displayName: "登録タイプ",
    fieldCamelCase: "registerType",
    type: "number",
  },
  {
    field: "external_id",
    fieldCamelCase: "externalId",
    type: "string",
  },
]

export const ProviderLineFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "account_id",
    fieldCamelCase: "accountId",
    type: "string",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const InformalOfferFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "user_name",
    fieldCamelCase: "userName",
    type: "string",
  },
  {
    field: "offer_type",
    fieldCamelCase: "offerType",
    type: "number",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const BouncedEmailFieldDefinition: FieldDefinition[] = [
  {
    field: "address",
    fieldCamelCase: "address",
    type: "string",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const EmployeeVoiceFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "name",
    fieldCamelCase: "name",
    type: "string",
  },
  {
    field: "department",
    fieldCamelCase: "department",
    type: "string",
  },
  {
    field: "join_year",
    fieldCamelCase: "joinYear",
    type: "string",
  },
  {
    field: "opportunity",
    fieldCamelCase: "opportunity",
    type: "string",
  },
  {
    field: "worth",
    fieldCamelCase: "worth",
    type: "string",
  },
  {
    field: "message",
    fieldCamelCase: "message",
    type: "string",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const AdminStaffFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "name",
    fieldCamelCase: "name",
    type: "string",
  },
  {
    field: "password_digest",
    fieldCamelCase: "passwordDigest",
    type: "string",
  },
  {
    field: "role",
    fieldCamelCase: "role",
    type: "number",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const UserFavoriteFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const UserHavereadFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "message_id",
    fieldCamelCase: "messageId",
    type: "number",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const UserCareerHistoryFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "content",
    fieldCamelCase: "content",
    type: "string",
  },
  {
    field: "date",
    fieldCamelCase: "date",
    type: "date",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const UserPrefectureFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "prefecture_type",
    fieldCamelCase: "prefectureType",
    type: "number",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const UserTokenFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "activation_token",
    fieldCamelCase: "activationToken",
    type: "string",
  },
  {
    field: "activation_token_send_at",
    fieldCamelCase: "activationTokenSendAt",
    type: "date",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
  {
    field: "reset_password_token",
    fieldCamelCase: "resetPasswordToken",
    type: "string",
  },
  {
    field: "reset_password_token_expires_at",
    fieldCamelCase: "resetPasswordTokenExpiresAt",
    type: "date",
  },
  {
    field: "activation_email_token",
    fieldCamelCase: "activationEmailToken",
    type: "string",
  },
  {
    field: "unverified_email",
    fieldCamelCase: "unverifiedEmail",
    type: "string",
  },
  {
    field: "line_nonce",
    fieldCamelCase: "lineNonce",
    type: "string",
  },
]

export const UserJobFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "job_type",
    fieldCamelCase: "jobType",
    type: "number",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const UserQualificationFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "qualification_id",
    fieldCamelCase: "qualificationId",
    type: "number",
  },
  {
    field: "name",
    fieldCamelCase: "name",
    type: "string",
  },
  {
    field: "date",
    fieldCamelCase: "date",
    type: "date",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const UserAcademicHistoryFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "school",
    fieldCamelCase: "school",
    type: "string",
  },
  {
    field: "faculty",
    fieldCamelCase: "faculty",
    type: "string",
  },
  {
    field: "major_faculty",
    fieldCamelCase: "majorFaculty",
    type: "string",
  },
  {
    field: "status",
    fieldCamelCase: "status",
    type: "number",
  },
  {
    field: "school_kind",
    fieldCamelCase: "schoolKind",
    type: "number",
  },
  {
    field: "date",
    fieldCamelCase: "date",
    type: "date",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const UserAssessmentFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "name",
    fieldCamelCase: "name",
    type: "string",
  },
  {
    field: "uniq_key",
    displayName: "診断結果URL",
    fieldCamelCase: "uniqKey",
    type: "string",
  },
  {
    field: "shared_key",
    displayName: "シェアページURL",
    fieldCamelCase: "sharedKey",
    type: "string",
  },
  {
    field: "status",
    displayName: "ステータス",
    fieldCamelCase: "status",
    type: "number",
  },
  {
    field: "style_type",
    displayName: "性別",
    fieldCamelCase: "styleType",
    type: "number",
  },
  {
    field: "result",
    displayName: "回答（設問）",
    fieldCamelCase: "result",
    type: "object",
  },
  {
    field: "answer",
    displayName: "回答（画像）",
    fieldCamelCase: "answer",
    type: "object",
  },
  {
    field: "birthday",
    displayName: "生年月日",
    fieldCamelCase: "birthday",
    type: "date",
  },
  {
    field: "last_reset_at",
    fieldCamelCase: "lastResetAt",
    type: "date",
  },
  {
    field: "birth_time_type",
    displayName: "生まれた時間",
    fieldCamelCase: "birthTimeType",
    type: "number",
  },
  {
    field: "created_at",
    displayName: "登録日",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    displayName: "更新日",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const UserSecretFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "company_name",
    fieldCamelCase: "companyName",
    type: "string",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const EmployeeAssessmentFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "company_id",
    displayName: "企業ID",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "company_name",
    displayName: "企業名",
    fieldCamelCase: "companyName",
    type: "number",
  },
  {
    field: "email",
    displayName: "メールアドレス",
    fieldCamelCase: "email",
    type: "string",
  },
  {
    field: "password_digest",
    fieldCamelCase: "passwordDigest",
    type: "string",
  },
  {
    field: "name",
    displayName: "診断者名",
    fieldCamelCase: "name",
    type: "string",
  },
  {
    field: "department",
    displayName: "部署",
    fieldCamelCase: "department",
    type: "string",
  },
  {
    field: "status",
    displayName: "ステータス",
    fieldCamelCase: "status",
    type: "number",
  },
  {
    field: "result",
    displayName: "回答（設問）",
    fieldCamelCase: "result",
    type: "object",
  },
  {
    field: "answer",
    displayName: "回答（画像）",
    fieldCamelCase: "answer",
    type: "object",
  },
  {
    field: "birthday",
    displayName: "生年月日",
    fieldCamelCase: "birthday",
    type: "date",
  },
  {
    field: "birth_time_type",
    displayName: "生まれた時間",
    fieldCamelCase: "birthTimeType",
    type: "number",
  },
  {
    field: "created_at",
    displayName: "作成日",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    displayName: "更新日",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const MatchingScoreFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "skill_fit",
    fieldCamelCase: "skillFit",
    type: "number",
  },
  {
    field: "culture_fit",
    fieldCamelCase: "cultureFit",
    type: "number",
  },
  {
    field: "total",
    fieldCamelCase: "total",
    type: "number",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
  {
    field: "employee_included",
    fieldCamelCase: "employeeIncluded",
    type: "boolean",
  },
]

export const HelloWorkJobFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "data_id",
    fieldCamelCase: "dataId",
    type: "string",
  },
  {
    field: "priority",
    fieldCamelCase: "priority",
    type: "number",
  },
  {
    field: "required_count",
    fieldCamelCase: "requiredCount",
    type: "number",
  },
  {
    field: "registered_count",
    fieldCamelCase: "registeredCount",
    type: "number",
  },
  {
    field: "current_page",
    fieldCamelCase: "currentPage",
    type: "number",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const UserLikeFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "likable_unit_type",
    fieldCamelCase: "likableUnitType",
    type: "string",
  },
  {
    field: "likable_unit_id",
    fieldCamelCase: "likableUnitId",
    type: "number",
  },
  {
    field: "display_name",
    fieldCamelCase: "displayName",
    type: "string",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const QualificationFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "group_id",
    fieldCamelCase: "groupId",
    type: "number",
  },
  {
    field: "name",
    fieldCamelCase: "name",
    type: "string",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const CompanyLikeFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "likable_unit_type",
    fieldCamelCase: "likableUnitType",
    type: "string",
  },
  {
    field: "likable_unit_id",
    fieldCamelCase: "likableUnitId",
    type: "number",
  },
  {
    field: "display_name",
    fieldCamelCase: "displayName",
    type: "string",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const MessageFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "sender_type",
    fieldCamelCase: "senderType",
    type: "string",
  },
  {
    field: "content",
    fieldCamelCase: "content",
    type: "string",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const CompanyFavoriteFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const CompanyHavereadFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "user_id",
    fieldCamelCase: "userId",
    type: "number",
  },
  {
    field: "message_id",
    fieldCamelCase: "messageId",
    type: "number",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const CompanyTokenFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "activation_token",
    fieldCamelCase: "activationToken",
    type: "string",
  },
  {
    field: "activation_token_expires_at",
    fieldCamelCase: "activationTokenExpiresAt",
    type: "date",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
  {
    field: "reset_password_token",
    fieldCamelCase: "resetPasswordToken",
    type: "string",
  },
  {
    field: "reset_password_token_expires_at",
    fieldCamelCase: "resetPasswordTokenExpiresAt",
    type: "date",
  },
  {
    field: "activation_email_token",
    fieldCamelCase: "activationEmailToken",
    type: "string",
  },
  {
    field: "unverified_email",
    fieldCamelCase: "unverifiedEmail",
    type: "string",
  },
]

export const CompanyRecruitFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "job_type",
    fieldCamelCase: "jobType",
    displayName: "求人を行う職種",
    type: "number",
  },
  {
    field: "job_description",
    fieldCamelCase: "jobDescription",
    displayName: "このポジションの具体的な仕事内容",
    type: "string",
  },
  {
    field: "skill",
    fieldCamelCase: "skill",
    displayName: "望ましいスキル",
    type: "string",
  },
  {
    field: "salary",
    fieldCamelCase: "salary",
    displayName: "基本給",
    type: "string",
  },
  {
    field: "allowance",
    fieldCamelCase: "allowance",
    displayName: "手当",
    type: "string",
  },
  {
    field: "bonus",
    fieldCamelCase: "bonus",
    displayName: "賞与",
    type: "string",
  },
  {
    field: "salary_promotion",
    fieldCamelCase: "salaryPromotion",
    displayName: "昇給",
    type: "string",
  },
  {
    field: "employee_benefit",
    fieldCamelCase: "employeeBenefit",
    displayName: "福利厚生・加入保険",
    type: "string",
  },
  {
    field: "vacation",
    fieldCamelCase: "vacation",
    displayName: "休日・休暇",
    type: "string",
  },
  {
    field: "working_time",
    fieldCamelCase: "workingTime",
    displayName: "勤務時間",
    type: "string",
  },
  {
    field: "break_time",
    fieldCamelCase: "breakTime",
    displayName: "休憩時間",
    type: "string",
  },
  {
    field: "in_house_system",
    fieldCamelCase: "inHouseSystem",
    displayName: "社内制度",
    type: "string",
  },
  {
    field: "placement",
    fieldCamelCase: "placement",
    displayName: "就業場所",
    type: "string",
  },
  {
    field: "employment_period",
    fieldCamelCase: "employmentPeriod",
    displayName: "契約期間",
    type: "string",
  },
  {
    field: "trial_period",
    fieldCamelCase: "trialPeriod",
    displayName: "試用期間",
    type: "string",
  },
  {
    field: "overworking_time",
    fieldCamelCase: "overworkingTime",
    displayName: "時間外労働",
    type: "string",
  },
  {
    field: "str_new_graduate",
    fieldCamelCase: "strNewGraduate",
    displayName: "新卒採用",
    type: "string",
  },
  {
    field: "str_second_new_graduate",
    fieldCamelCase: "strSecondNewGraduate",
    displayName: "第二新卒・既卒採用（25才以下）",
    type: "string",
  },
  {
    field: "str_mid_carrer",
    fieldCamelCase: "strMidCarrer",
    displayName: "中途採用（26才以上）",
    type: "string",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    displayName: "登録日",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    displayName: "更新日",
    type: "date",
  },
]

export const CompanyIntroductionFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    type: "number",
  },
  {
    field: "name",
    fieldCamelCase: "name",
    type: "string",
  },
  {
    field: "created_at",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const CompanyProfileFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "name",
    displayName: "企業名",
    fieldCamelCase: "name",
    type: "string",
  },
  {
    field: "address",
    displayName: "本社所在地",
    fieldCamelCase: "address",
    type: "string",
  },
  {
    field: "founded_date",
    displayName: "設立日",
    fieldCamelCase: "foundedDate",
    type: "string",
  },
  {
    field: "representative",
    displayName: "代表者",
    fieldCamelCase: "representative",
    type: "string",
  },
  {
    field: "capital",
    displayName: "資本金",
    fieldCamelCase: "capital",
    type: "string",
  },
  {
    field: "url",
    displayName: "サイトURL",
    fieldCamelCase: "url",
    type: "string",
  },
  {
    field: "twitter_url",
    fieldCamelCase: "twitterUrl",
    type: "string",
  },
  {
    field: "facebook_url",
    fieldCamelCase: "facebookUrl",
    type: "string",
  },
  {
    field: "instagram_url",
    fieldCamelCase: "instagramUrl",
    type: "string",
  },
  {
    field: "tel",
    displayName: "電話番号",
    fieldCamelCase: "tel",
    type: "string",
  },
  {
    field: "main_client",
    displayName: "主要取引先",
    fieldCamelCase: "mainClient",
    type: "string",
  },
  {
    field: "created_at",
    displayName: "登録日",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    displayName: "更新日",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
  {
    field: "founded_at",
    fieldCamelCase: "foundedAt",
    type: "date",
  },
  {
    field: "prefecture_type",
    displayName: "都道府県ID",
    fieldCamelCase: "prefectureType",
    type: "number",
  },
  {
    field: "prefecture",
    displayName: "都道府県",
    fieldCamelCase: "prefecture",
    type: "string",
  },
]

export const CompanyDetailFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "introduction",
    displayName: "会社紹介",
    fieldCamelCase: "introduction",
    type: "string",
  },
  {
    field: "cherishing",
    displayName: "大切にしていること",
    fieldCamelCase: "cherishing",
    type: "string",
  },
  {
    field: "story",
    displayName: "会社を表す象徴的なストーリー",
    fieldCamelCase: "story",
    type: "string",
  },
  {
    field: "vision",
    displayName: "理念(ビジョン)、行動指針など",
    fieldCamelCase: "vision",
    type: "string",
  },
  {
    field: "effort",
    displayName: "面白い取り組みや制度など",
    fieldCamelCase: "effort",
    type: "string",
  },
  {
    field: "crisis_episode",
    displayName: "これまでに起こった危機と、それをどう乗り越えたか",
    fieldCamelCase: "crisisEpisode",
    type: "string",
  },
  {
    field: "activity",
    displayName: "社外での活動について",
    fieldCamelCase: "activity",
    type: "string",
  },
  {
    field: "appeal",
    displayName: "職場の雰囲気や仕事のしかたで自慢したいこと",
    fieldCamelCase: "appeal",
    type: "string",
  },
  {
    field: "str_publish_job_desc",
    displayName: "仕事の内容の開示",
    fieldCamelCase: "strPublishJobDesc",
    type: "string",
  },
  {
    field: "str_publish_job_procedures",
    displayName: "仕事の進め方の開示",
    fieldCamelCase: "strPublishJobProcedures",
    type: "string",
  },
  {
    field: "str_publish_ideal_figure",
    displayName: "理想の人物像の開示",
    fieldCamelCase: "strPublishIdealFigure",
    type: "string",
  },
  {
    field: "str_publish_required_ability",
    displayName: "求められる能力の開示",
    fieldCamelCase: "strPublishRequiredAbility",
    type: "string",
  },
  {
    field: "all_employees",
    displayName: "全社員数",
    fieldCamelCase: "allEmployees",
    type: "number",
  },
  {
    field: "men",
    displayName: "男性社員数",
    fieldCamelCase: "men",
    type: "number",
  },
  {
    field: "women",
    displayName: "女性社員数",
    fieldCamelCase: "women",
    type: "number",
  },
  {
    field: "teens",
    displayName: "10代社員数",
    fieldCamelCase: "teens",
    type: "number",
  },
  {
    field: "twenties",
    displayName: "20代社員数",
    fieldCamelCase: "twenties",
    type: "number",
  },
  {
    field: "thirties",
    displayName: "30代社員数",
    fieldCamelCase: "thirties",
    type: "number",
  },
  {
    field: "forties",
    displayName: "40代社員数",
    fieldCamelCase: "forties",
    type: "number",
  },
  {
    field: "fifteenth",
    displayName: "50代社員数",
    fieldCamelCase: "fifteenth",
    type: "number",
  },
  {
    field: "after_sixties",
    displayName: "60代以上社員数",
    fieldCamelCase: "afterSixties",
    type: "number",
  },
  {
    field: "sales",
    displayName: "売上",
    fieldCamelCase: "sales",
    type: "number",
  },
  {
    field: "income",
    displayName: "営業利益",
    fieldCamelCase: "income",
    type: "number",
  },
  {
    field: "sales_before1",
    displayName: "1期前の売上",
    fieldCamelCase: "salesBefore1",
    type: "number",
  },
  {
    field: "salese_before2",
    displayName: "2期前の売上",
    fieldCamelCase: "salesBefore2",
    type: "number",
  },
  {
    field: "capital_adequacy_ratio",
    displayName: "自己資本比率",
    fieldCamelCase: "capitalAdequacyRatio",
    type: "number",
  },
  {
    field: "new_graduates",
    displayName: "今期の新卒採用数",
    fieldCamelCase: "newGraduates",
    type: "number",
  },
  {
    field: "new_graduates_before1",
    displayName: "前期の新卒採用数",
    fieldCamelCase: "newGraduatesBefore1",
    type: "number",
  },
  {
    field: "new_graduates_before2",
    displayName: "2期前の新卒採用数",
    fieldCamelCase: "newGraduatesBefore2",
    type: "number",
  },
  {
    field: "turnovers",
    displayName: "今期の離職者数",
    fieldCamelCase: "turnovers",
    type: "number",
  },
  {
    field: "turnovers_before1",
    displayName: "前期の離職者数",
    fieldCamelCase: "turnoversBefore1",
    type: "number",
  },
  {
    field: "turnovers_before2",
    displayName: "2期前の離職者数",
    fieldCamelCase: "turnoversBefore2",
    type: "number",
  },
  {
    field: "avg_duration_of_service",
    displayName: "平均勤続年数",
    fieldCamelCase: "avgDurationOfService",
    type: "number",
  },
  {
    field: "avg_overtime_hours",
    displayName: "月平均所定外労働時間",
    fieldCamelCase: "avgOvertimeHours",
    type: "number",
  },
  {
    field: "avg_taken_paid_leaves",
    displayName: "有給休暇の平均取得日数",
    fieldCamelCase: "avgTakenPaidLeaves",
    type: "number",
  },
  {
    field: "taken_childcare_leave_men_ratio",
    displayName: "育児休暇の取得社比率（男性）",
    fieldCamelCase: "takenChildcareLeaveMenRatio",
    type: "number",
  },
  {
    field: "taken_childcare_leave_women_ratio",
    displayName: "育児休暇の取得社比率（女性）",
    fieldCamelCase: "takenChildcareLeaveWomenRatio",
    type: "number",
  },
  {
    field: "created_at",
    displayName: "登録日",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    displayName: "更新日",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

export const CompanyAssessmentFieldDefinition: FieldDefinition[] = [
  {
    field: "id",
    fieldCamelCase: "id",
    type: "number",
  },
  {
    field: "company_id",
    fieldCamelCase: "companyId",
    displayName: "企業ID",
    type: "number",
  },
  {
    field: "company_name",
    fieldCamelCase: "companyName",
    displayName: "企業名",
    type: "string",
  },
  {
    field: "email",
    displayName: "メールアドレス",
    fieldCamelCase: "email",
    type: "string",
  },
  {
    field: "name",
    displayName: "診断者名",
    fieldCamelCase: "name",
    type: "string",
  },
  {
    field: "department",
    displayName: "求人を行う部署",
    fieldCamelCase: "department",
    type: "string",
  },
  {
    field: "status",
    displayName: "ステータス",
    fieldCamelCase: "status",
    type: "number",
  },
  {
    field: "result",
    displayName: "回答（設問）",
    fieldCamelCase: "result",
    type: "object",
  },
  {
    field: "created_at",
    displayName: "登録日",
    fieldCamelCase: "createdAt",
    type: "date",
  },
  {
    field: "updated_at",
    displayName: "更新日",
    fieldCamelCase: "updatedAt",
    type: "date",
  },
]

