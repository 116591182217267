import React, { useState, useEffect } from "react";
import { RowsProp, DataGrid, CellParams } from '@material-ui/data-grid';
import {
  Toolbar,
} from '@material-ui/core';
import { useHistory, generatePath } from "react-router-dom";

import { CompanyRepository } from 'domain/repository';
import { useRepository } from 'components/lib/Repository';
import { CustomPagination } from 'components/Pagination';
import { useFilter } from "hooks/useFilter";
import { FilterForm } from 'components/Filter';
import { CompanyFieldDefinition } from 'domain/model/field_difinition';
import { useIndex } from 'hooks/useIndex';
import routes from 'utils/routes';

const ignoreFields = ["password_digest", "str_recruiting", "business"]
const companyFieldDefinition = CompanyFieldDefinition.filter(v => !ignoreFields.includes(v.field) ).concat({
  field: "company_profiles.name",
  type: "string",
  fieldCamelCase: "name",
})

export const CompanyIndex = () => {
  const history = useHistory();
  const companyRepository = useRepository(CompanyRepository)
  const [rows, setRows] = useState<RowsProp>([]);
  const {
    page,
    totalPage,
    sort,
    sortingMode,
    handlePageChange,
    handleSortChange,
    changeTotal,
  } = useIndex();
  const { filters, handleSetFilters, } = useFilter();

  useEffect(() => {
    companyRepository.fetchIndex({page, sort, filters: filters}).then(res => {
      const { total } = res;
      changeTotal(total);
      setRows(res.companies);
    });
  }, [page, sort, filters]);

  const handleClickCell = (param: CellParams): void => {
    const { field, value } = param;
    if (field === 'id') {
      const path = generatePath(routes.company.companyShow.path, {id: value as string});
      history.push(path);
    }
  }

   return (
    <>
      <Toolbar>企業一覧 </Toolbar>
      <FilterForm
        handleFilter={handleSetFilters}
        fieldDefinition={companyFieldDefinition}
      />
      <div style={{ width: '100%', minHeight: 600 }}>
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'id',
            },
            {
              field: 'name',
              width: 300,
              sortable: false,
              headerName: '企業名',
            },
            {
              field: 'email',
              width: 300,
              headerName: 'メールアドレス',
            },
            {
              field: 'department',
              width: 200,
              sortable: false,
              headerName: '求人を行う部署',
            },
            {
              field: 'business',
              sortable: false,
              width: 200,
              headerName: '企業の業界・業種',
            },
            {
              field: 'businessId',
              sortable: false,
              width: 200,
              headerName: '企業の業界・業種ID',
            },
            {
              field: 'strRecruiting',
              sortable: false,
              width: 200,
              headerName: '採用中',
            },
            {
              field: 'recruiter',
              sortable: false,
              width: 200,
              headerName: '採用担当者',
            },
            {
              field: 'status',
              width: 200,
              sortable: false,
              headerName: 'ステータス',
            },
            {
              field: 'createdAt',
              width: 200,
              sortable: false,
              headerName: '登録日',
            },
            {
              field: 'updatedAt',
              width: 200,
              sortable: false,
              headerName: '更新日',
            },
          ]}
          pagination
          rowCount={totalPage}
          paginationMode="server"
          sortingMode={sortingMode}
          onCellClick={handleClickCell}
          onPageChange={handlePageChange}
          onSortModelChange={handleSortChange}
          components={{pagination: CustomPagination}}
          rowsPerPageOptions={[100]}
          disableColumnResize={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          loading={false}
        />
      </div>
    </>
  );
};
