import { Expose } from "class-transformer";
import { CompanyBase } from "domain/model/base";

export default class Company extends CompanyBase {
  @Expose({name: "name"})
  name?: string;

  @Expose({name: "business"})
  business?: string;

  @Expose({name: "str_recruiting"})
  strRecruiting?: string;
};
