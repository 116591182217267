import { CompanyRecruitBase } from "domain/model/base";
import { Expose } from "class-transformer";

export default class CompanyRecruit extends CompanyRecruitBase {
  @Expose({name: "str_new_graduate"})
  strNewGraduate?: string;

  @Expose({name: "str_second_new_graduate"})
  strSecondNewGraduate?: string;

  @Expose({name: "str_mid_carrer"})
  strMidCarrer?: string;
};
