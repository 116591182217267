import { Dashboard } from 'pages/Dashboard';
import { CompanyIndex } from 'pages/Company/Index';
import { CompanyAssessmentIndex } from 'pages/CompanyAssessment/Index';
import { CompanyAssessmentShow } from 'pages/CompanyAssessment/Show';
import { EmployeeAssessmentShow } from 'pages/EmployeeAssessment/Show';
import { EmployeeAssessmentIndex } from 'pages/EmployeeAssessment/Index';
import { UserAssessmentShow } from 'pages/UserAssessment/Show';
import { UserAssessmentIndex } from 'pages/UserAssessment/Index';
import { UserInformalOfferIndex } from 'pages/UserInformalOffer/Index';
import { CompanyShow } from 'pages/Company/Show';
import { UserIndex } from 'pages/User/Index';
import { UserShow } from 'pages/User/Show';
import { UserFactsheetShow } from 'pages/UserFactsheet/Show';
import { Login } from 'pages/Login';
import { InformalOfferIndex } from 'pages/InformalOffer/Index';
import { MessageIndex } from 'pages/Message/Index';
import { OfficialMessageIndex } from 'pages/OfficialMessage/Index';
import { CsvIndex } from 'pages/Csv/index';
import { AdminStaffIndex } from 'pages/AdminStaff/Index';
import { AdminStaffCreate } from 'pages/AdminStaff/Create';
import { AdminStaffEdit } from 'pages/AdminStaff/Edit';

const routes = {
  common: {
    login: {
      path: "/auth/login",
      component: Login,
    },
    dashboard: {
      path: "/dashboard",
      component: Dashboard,
    },
    messageIndex: {
      path: "/message",
      component: MessageIndex,
    },
    officialMessageIndex: {
      path: "/official_message",
      component: OfficialMessageIndex,
    },
    csv: {
      path: "/csv",
      component: CsvIndex,
    },
    adminStaffIndex: {
      path: "/admin_staff/index",
      component: AdminStaffIndex,
    },
    adminStaffCreate: {
      path: "/admin_staff/create",
      component: AdminStaffCreate,
    },
    adminStaffEdit: {
      path: "/admin_staff/edit",
      component: AdminStaffEdit,
    },
  },
  company: {
    companyIndex: {
      path: "/company/index",
      component: CompanyIndex,
    },
    companyShow: {
      path: "/company/show/:id",
      component: CompanyShow,
    },
    companyAssessmentIndex: {
      path: "/company/company_assessment/index",
      component: CompanyAssessmentIndex,
    },
    companyAssessmentShow: {
      path: "/company/company_assessment/show/:id",
      component: CompanyAssessmentShow,
    },
    employeeAssessmentIndex: {
      path: "/company/employee_assessment/index",
      component: EmployeeAssessmentIndex,
    },
    employeeAssessmentShow: {
      path: "/company/employee_assessment/show/:id",
      component: EmployeeAssessmentShow,
    },
    informalOfferIndex: {
      path: "/company/informal_offer/index",
      component: InformalOfferIndex,
    },
  },
  user: {
    userIndex: {
      path: "/user/index",
      component: UserIndex,
    },
    userShow: {
      path: "/user/show/:id",
      component: UserShow,
    },
    userAssessmentIndex: {
      path: "/user/assessment/index",
      component: UserAssessmentIndex,
    },
    userAssessmentShow: {
      path: "/user/assessment/show/:id",
      component: UserAssessmentShow,
    },
    userFactsheetShow: {
      path: "/user/factsheet/show/:id",
      component: UserFactsheetShow,
    },
    userInformalOfferIndex: {
      path: "/user/informal_offer/index",
      component: UserInformalOfferIndex,
    },
  }
} as const;

export default routes;
